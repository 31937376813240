// this is becaise of the src dir in nuxt.config.js
import { theme } from '../../theme';

export default function () {
  return {
    theme: {
      options: {
        customProperties: true,
      },
      dark: false,
      themes: {
        light: theme.vuetifyTheme[process.env.ENV_THEME],
        dark: theme.vuetifyTheme[process.env.ENV_THEME],
      },
    },
  };
}
