<template>
  <v-layout justify-center align-start>
    <div style="width: fit-content;">
      <h1 v-if="error.statusCode === 404">
        Not found
      </h1>
      <h1 v-else-if="error.statusCode === 403">
        Unauthorized
      </h1>
      <h1 v-else>
        {{ otherError }}
      </h1>
      <div>
        <a @click="goBack">
          Go back
        </a>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      otherError: this.error.message || 'An error occurred',
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title,
    };
  },
};
</script>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
