export default async ({ graphQLErrors, networkError }, { error, redirect, store }) => {
  if (networkError && networkError.statusCode === 401) {
    await store.dispatch('signOut');
    redirect('/');
    return null;
  }
  if (networkError && networkError.statusCode === 403) {
    error({ statusCode: 403 });
    return null;
  }
  if (graphQLErrors === []) return null;
  if (graphQLErrors.some(({ extensions }) => extensions && extensions.code === 'UNAUTHORIZED')) {
    error({ statusCode: 403 });
    return null;
  }

  if (graphQLErrors.some(({ extensions }) => extensions && extensions.code === 'NOT_FOUND')) {
    error({ statusCode: 404 });
    return null;
  }
  return null;
};
