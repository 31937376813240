<template>
  <v-app>
    <v-app-bar fixed app clipped-left>
      <img :src="require(`~/assets/images/${headerImgName}`)" height="40" />
    </v-app-bar>
    <v-main>
      <v-alert
        v-for="notification in notifications"
        :key="notification.id"
        dense
        type="success"
        class="my-1 py-2"
        color="primary"
        :dismissible="true"
        @input="removeNotification(notification.id)"
      >
        {{ notification.text }}
      </v-alert>
      <v-container fluid fill-height d-flex justify-center>
        <nuxt />
      </v-container>
    </v-main>
    <v-footer :fixed="true" app>
      <span>&copy; {{ copyrightYear }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { theme } from '../theme';

export default {
  data() {
    return {
      headerImgName: theme.header_logo[process.env.ENV_THEME],
      copyrightYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState(['notifications']),
  },
  methods: {
    ...mapMutations(['removeNotification']),
  },
  errorCaptured(error) {
    if (this.$nuxt.context.isDev) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return !this.$nuxt.context.isDev;
  },
};
</script>
