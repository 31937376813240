import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _87e4515e = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _514739f5 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _4841114d = () => interopDefault(import('../pages/forgotPassword.vue' /* webpackChunkName: "pages/forgotPassword" */))
const _751896a7 = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _734b14eb = () => interopDefault(import('../pages/resetPassword.vue' /* webpackChunkName: "pages/resetPassword" */))
const _08650331 = () => interopDefault(import('../pages/signIn.vue' /* webpackChunkName: "pages/signIn" */))
const _1cfb59a7 = () => interopDefault(import('../pages/signUp.vue' /* webpackChunkName: "pages/signUp" */))
const _5ea01bfa = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _2bd5ba97 = () => interopDefault(import('../pages/requisitions/new.vue' /* webpackChunkName: "pages/requisitions/new" */))
const _4248b918 = () => interopDefault(import('../pages/requisitions/_id/index.vue' /* webpackChunkName: "pages/requisitions/_id/index" */))
const _4f644158 = () => interopDefault(import('../pages/requisitions/_id/edit.vue' /* webpackChunkName: "pages/requisitions/_id/edit" */))
const _56e6c6e6 = () => interopDefault(import('../pages/requisitions/_id/submit.vue' /* webpackChunkName: "pages/requisitions/_id/submit" */))
const _bc22149a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _87e4515e,
    name: "account"
  }, {
    path: "/dashboard",
    component: _514739f5,
    name: "dashboard"
  }, {
    path: "/forgotPassword",
    component: _4841114d,
    name: "forgotPassword"
  }, {
    path: "/payment",
    component: _751896a7,
    name: "payment"
  }, {
    path: "/resetPassword",
    component: _734b14eb,
    name: "resetPassword"
  }, {
    path: "/signIn",
    component: _08650331,
    name: "signIn"
  }, {
    path: "/signUp",
    component: _1cfb59a7,
    name: "signUp"
  }, {
    path: "/welcome",
    component: _5ea01bfa,
    name: "welcome"
  }, {
    path: "/requisitions/new",
    component: _2bd5ba97,
    name: "requisitions-new"
  }, {
    path: "/requisitions/:id?",
    component: _4248b918,
    name: "requisitions-id"
  }, {
    path: "/requisitions/:id?/edit",
    component: _4f644158,
    name: "requisitions-id-edit"
  }, {
    path: "/requisitions/:id?/submit",
    component: _56e6c6e6,
    name: "requisitions-id-submit"
  }, {
    path: "/",
    component: _bc22149a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
