export default function ({ $axios, store }) {
  $axios.onRequest((config) => {
    if (store.state.user) {
      config.headers.common = {
        ...config.headers.common,
        'X-User-Email': store.state.user.email,
        'X-User-Token': store.state.user.authenticationToken,
      };
    }
    return config;
  });
}
