import * as firebase from 'firebase/app'

const config = {"apiKey":"AIzaSyA2KrLp3GV-qNONnK0wvmraFPC5Ijpr67M","authDomain":"staging-iqtx.firebaseapp.com","databaseURL":"https:\u002F\u002Fstaging-iqtx.firebaseio.com","projectId":"staging-iqtx","storageBucket":"staging-iqtx.appspot.com","messagingSenderId":"996014920054","appId":"1:996014920054:web:c7482a77109d29a90daa57","measurementId":"G-E62WZJ4MEC"}

export default async ({ res }, inject) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(config)
  }
  const session = firebase.apps[0]

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE PERFORMANCE ------------------------------------ **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Performance can only be initiated on client side
  if(process.client) {
    await import('firebase/performance')

    const firePerf = session.performance()
    const firePerfObj = firebase.performance
    inject('firePerf', firePerf)
    inject('firePerfObj', firePerfObj)
  }

  /** --------------------------------------------------------------------------------------------- **/
  /** ----------------------------------- FIREBASE ANALYTICS -------------------------------------- **/
  /** --------------------------------------------------------------------------------------------- **/

  // Firebase Analytics can only be initiated on the client side
  if (process.client) {
    await import('firebase/analytics')

    const fireAnalytics = session.analytics()
    const fireAnalyticsObj = firebase.analytics
    inject('fireAnalytics', fireAnalytics)
    inject('fireAnalyticsObj', fireAnalyticsObj)

    // In development we want to disable analytics collection
    fireAnalytics.setAnalyticsCollectionEnabled(true)
  }
}
