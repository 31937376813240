<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" stateless clipped app>
      <v-list nav dense>
        <v-subheader>Navigation</v-subheader>
        <v-list-item :nuxt="true" to="/dashboard">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item :nuxt="true" to="/account">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item>
        <v-divider />
        <v-list-item @click="signOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="primary" href="mailto:iqrecruit@iqtalentpartners.com">
            <v-icon class="pr-2">
              mdi-email
            </v-icon>
            Email Us
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar fixed app clipped-left>
      <v-app-bar-nav-icon @click="drawer = !drawer">
        <template v-slot:default>
          <v-icon>mdi-{{ drawer ? 'close' : 'menu' }}</v-icon>
        </template>
      </v-app-bar-nav-icon>
      <nuxt-link to="/dashboard" style="display: inline-flex;">
        <img :src="require(`~/assets/images/${headerImgName}`)" height="40" />
      </nuxt-link>
      <v-spacer />
      <v-badge
        class="mr-1"
        color="#0c3"
        :bordered="true"
        :value="!$apollo.queries.me.loading"
        offset-x="20"
        offset-y="20"
        title="Research Credits"
      >
        <template v-slot:badge>
          {{ researchCreditBalance }}
        </template>
        <nuxt-link to="/account">
          <v-avatar size="50">
            <img :src="require('~/assets/images/Research-Icon.png')" height="50" />
          </v-avatar>
        </nuxt-link>
      </v-badge>
      <v-badge
        class="mr-2"
        color="#0c3"
        :bordered="true"
        :value="!$apollo.queries.me.loading"
        offset-x="20"
        offset-y="20"
        title="Diversify Credits"
      >
        <template v-slot:badge>
          {{ diversifyCreditBalance }}
        </template>
        <nuxt-link to="/account">
          <v-avatar size="50">
            <img :src="require('~/assets/images/Sourcing-Icon.png')" height="50" />
          </v-avatar>
        </nuxt-link>
      </v-badge>
      <v-btn v-if="user" color="primary" nuxt to="/payment" class="mr-2">
        Buy Credits
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-alert
        v-for="notification in notifications"
        :key="notification.id"
        dense
        type="success"
        class="my-1 py-2"
        color="primary"
        :dismissible="true"
        @input="removeNotification(notification.id)"
      >
        {{ notification.text }}
      </v-alert>
      <v-container fluid fill-height justify-center align-content-start>
        <nuxt />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import { theme } from '../theme';
import * as Queries from '~/graphql/queries.gql';

export default {
  data() {
    return {
      drawer: false,
      headerImgName: theme.header_logo[process.env.ENV_THEME],
      year: moment().year(),
      me: null,
    };
  },
  apollo: {
    me: {
      query: Queries.UserInfo,
    },
  },
  computed: {
    ...mapState(['user', 'notifications']),
    diversifyCreditBalance() {
      return this.me ? this.me.iqtxEngagement.diversifyCreditBalance : '';
    },
    researchCreditBalance() {
      return this.me ? this.me.iqtxEngagement.researchCreditBalance : '';
    },
  },
  methods: {
    ...mapActions(['signOut']),
    ...mapMutations(['removeNotification']),
  },
  errorCaptured(error) {
    if (this.$nuxt.context.isDev) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return !this.$nuxt.context.isDev;
  },
};
</script>
