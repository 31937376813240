export const theme = {
  title: {
    development: 'localhost',
    staging: 'Staging - IQRecruit',
    production: 'IQRecruit',
  },
  favicon: {
    development: '/favicon_dev.png',
    staging: '/favicon_staging.png',
    production: '/favicon.png',
  },
  rectangle_logo: {
    development: 'IQTPx-sm_dev.png',
    staging: 'IQTPx-sm_staging.png',
    production: 'IQTPx-sm.png',
  },
  header_logo: {
    development: 'iqrecruit-dev.png',
    staging: 'iqrecuit-staging.png',
    production: 'iqrecruit-prod.png',
  },
  vuetifyTheme: {
    development: {
      primary: '#0c3',
      secondary: '#0a3',
      accent: '#0c3',
      // success: '#0c3',
    },
    staging: {
      primary: '#6c00e7',
      secondary: '#4b00a1',
      accent: '#6c00e7',
      // success: '#6c00e7',
    },
    production: {
      primary: '#da2128',
      secondary: '#8b0000',
      accent: '#d64c51',
      // success: '#da2128',
    },
  },
};
