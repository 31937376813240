import * as Queries from '~/graphql/queries.gql';

const getDefaultState = () => {
  return {
    cartItems: [],
    coupon: null,
    paymentType: 'newCard',
    cardToken: null,
    saveCard: false,
  };
};

export const state = () => {
  return getDefaultState();
};

// export const state = getDefaultState();

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  addItem(state, { product, quantity }) {
    const cartItems = state.cartItems.filter((cartItem) => cartItem.product.id !== product.id);
    if (quantity === 0) {
      this.$fireAnalytics.logEvent('remove_from_cart', {
        items: [convertCartItemToGoogleAnalyticsItem({ product, quantity })],
      });

      state.cartItems = cartItems;
    } else {
      this.$fireAnalytics.logEvent('add_to_cart', {
        items: [convertCartItemToGoogleAnalyticsItem({ product, quantity })],
      });
      state.cartItems = [...cartItems, { product, quantity }];
    }
  },

  setSaveCard(state, value) {
    state.saveCard = value;
  },

  setPaymentType(state, value) {
    state.paymentType = value;
  },

  setCardToken(state, value) {
    state.cardToken = value;
  },

  setCoupon(state, value) {
    state.coupon = value;
  },
};

export const actions = {
  beginCheckoutProcess({ commit }) {
    commit('resetState');
    this.$fireAnalytics.logEvent('begin_checkout');
  },

  async purchase({ state: { cardToken, saveCard, coupon }, getters: { cart } }) {
    const productQuantities = cart.map(({ product: { id }, quantity }) => ({ id, quantity }));
    const variables = {
      productQuantities,
      cardToken,
      saveCard,
    };

    if (coupon) {
      variables.couponCode = coupon.code;
    }

    const client = this.app.apolloProvider.defaultClient;
    const {
      error,
      data: { buyCredits },
    } = await client.mutate({
      mutation: Queries.BuyCredits,
      variables,
    });
    if (!error) {
      this.$fireAnalytics.logEvent('purchase', {
        currency: 'USD',
        value: this.totalAmount,
        transaction_id: buyCredits.id,
        items: cart.map(convertCartItemToGoogleAnalyticsItem),
      });
    }
    return error;
  },

  setPaymentType({ commit }, value) {
    commit('setPaymentType', value);
    commit('setCardToken', null);
    commit('setSaveCard', false);
  },

  async applyCoupon({ commit }, code) {
    const client = this.app.apolloProvider.defaultClient;
    const {
      data: { getCoupon },
    } = await client.mutate({
      mutation: Queries.GetCoupon,
      variables: {
        code,
      },
    });

    if (getCoupon) {
      this.$fireAnalytics.logEvent('select_promotion', { promotion_id: getCoupon.id, promotion_name: getCoupon.name });
    }

    commit('setCoupon', getCoupon);
    return getCoupon;
  },
};

export const getters = {
  subtotal({ cartItems }, { productCoupon }) {
    const preCouponTotal = cartItems.reduce((accumulator, cartItem) => {
      if (productCoupon && productCoupon.product.id === cartItem.product.id && cartItem.quantity >= 1) {
        return accumulator + cartItem.product.price * (cartItem.quantity - 1);
      }
      return accumulator + cartItem.product.price * cartItem.quantity;
    }, 0);
    if (productCoupon) {
      return preCouponTotal + productCoupon.productOverridePrice;
    }
    return preCouponTotal;
  },
  totalAmount(_, { subtotal, orderCoupon }) {
    if (orderCoupon) {
      const percentOff = (orderCoupon.orderDiscountPercent / 100) * subtotal;
      return subtotal - percentOff;
    }
    return subtotal;
  },
  itemsInCart(state) {
    return state.cartItems.length > 0;
  },
  orderCoupon({ coupon }, { subtotal }) {
    if (coupon && coupon.kind === 'order_discount') {
      const takeOff = (coupon.orderDiscountPercent / 100) * subtotal;
      return { ...coupon, takeOff };
    }
  },
  productCoupon({ coupon }) {
    if (coupon && coupon.kind === 'product_price_change') {
      const takeOff = coupon.product.price - coupon.productOverridePrice;
      return { ...coupon, takeOff };
    }
  },
  cart({ cartItems }, { productCoupon }) {
    if (productCoupon) {
      return cartItems
        .map((cartItem) => {
          if (productCoupon.product.id === cartItem.product.id && cartItem.quantity >= 1) {
            return { ...cartItem, quantity: cartItem.quantity - 1 };
          }
          return cartItem;
        })
        .filter((cartItem) => cartItem.quantity > 0);
    }
    return cartItems;
  },
};

function convertCartItemToGoogleAnalyticsItem({ product, quantity }) {
  return {
    item_id: product.id,
    item_name: product.name,
    price: product.price,
    quantity,
  };
}
