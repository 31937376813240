import Vue from 'vue';

Vue.filter('currency', (value) => {
  if (!value) return '$0';
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return currencyFormatter.format(value).split('.')[0];
});

Vue.filter('createHref', (value) => {
  return value && !value.includes('http') ? `http://${value}` : value;
});
