import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import omitDeep from 'omit-deep-lodash';
import { getMainDefinition } from 'apollo-utilities';
// import { onError } from 'apollo-link-error';

export default function ({ store, _error }) {
  const httpLink = createUploadLink({
    uri: `${process.env.API_URL}/iqtx/graphql`,
    credentials: 'omit',
  });

  const authLink = setContext((_, { headers }) => {
    const authHeaders = {};
    if (store.state.user) {
      const { email, authenticationToken } = store.state.user;
      authHeaders['X-User-Email'] = email;
      authHeaders['X-User-Token'] = authenticationToken;
    }
    const newHeaders = {
      ...headers,
      accept: 'application/json',
      ...authHeaders,
    };
    return {
      headers: newHeaders,
    };
  });

  const cleanTypenameLink = new ApolloLink((operation, forward) => {
    const keysToOmit = ['__typename']; // more keys like timestamps could be included here

    const def = getMainDefinition(operation.query);
    if (def && def.operation === 'mutation') {
      operation.variables = omitDeep(operation.variables, keysToOmit);
    }
    return forward ? forward(operation) : null;
  });

  return {
    connectToDevTools: true,
    link: ApolloLink.from([cleanTypenameLink, authLink.concat(httpLink)]),
    defaultHttpLink: false,
  };
}
