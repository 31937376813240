const nonAuthRouteNames = ['index', 'signIn', 'signUp', 'forgotPassword', 'resetPassword'];
export default function ({ store, redirect, route }) {
  if (store.state.user) {
    if (nonAuthRouteNames.some((testRoute) => testRoute === route.name)) {
      redirect('/dashboard');
    }
  } else if (nonAuthRouteNames.every((testRoute) => testRoute !== route.name)) {
    store.commit('addNotification', {
      text: 'You must login to view this page.',
    });
    redirect('/');
  }
}
