export const state = () => ({
  user: null,
  notifications: [],
  requisitionCandidateSortBy: {},
});

export const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  addNotification(state, notification) {
    const newNotification = {
      text: notification.text,
      type: notification.type,
      id: getNewId(state.notifications),
    };
    state.notifications.push(newNotification);
  },
  removeNotification(state, id) {
    state.notifications = state.notifications.filter((el) => {
      return el.id !== id;
    });
  },
  clearAllNotifications(state) {
    state.notifications = [];
  },
  addRequisitionCandidateSortBy(state, { id, sortBy, sortDesc }) {
    const tempStore = { ...state.requisitionCandidateSortBy, [id]: { sortBy, sortDesc } };
    if (sortBy.length === 0 && sortDesc.length === 0) {
      delete tempStore[id];
    }
    state.requisitionCandidateSortBy = tempStore;
  },
};

export const actions = {
  async signIn({ commit }, loginParams) {
    const response = await this.$axios
      .$post('/users/sign_in', {
        user: loginParams,
      })
      .catch((error) => error.response.data);

    if (response.error) {
      throw response.error;
    }
    this.$fireAnalytics.logEvent('login', { method: 'email' });
    this.$sentry.configureScope(function (scope) {
      scope.setUser({ email: response.email, id: response.id });
    });
    commit('clearAllNotifications');
    commit('setUser', response);
  },
  async signOut({ commit }) {
    await this.$axios.$delete('/users/sign_out');
    this.$axios.setHeader('X-User-Email', false);
    this.$axios.setHeader('X-User-Token', false);
    this.$sentry.configureScope((scope) => scope.setUser(null));
    await this.app.apolloProvider.defaultClient.clearStore();
    commit('setUser', null);
    commit('clearAllNotifications');
    await this.$router.push('/');
  },
  async signUp({ commit }, signUpParams) {
    const response = await this.$axios
      .$post('/users/sign_up', {
        user: signUpParams,
      })
      .catch((error) => error.response.data);
    if (response.errors) {
      throw response.errors;
    } else {
      this.$fireAnalytics.logEvent('sign_up', { method: 'email' });
      commit('setUser', response);
    }
  },
};

function getNewId(notifications) {
  let newId = 1;
  for (let i = 0; i < notifications.length; i++) {
    newId = notifications[i].id >= newId ? notifications[i].id + 1 : newId;
  }
  return newId;
}
